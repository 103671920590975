import { Vue, Component } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'

@Component
export default class DiscountGroupsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$emailTemplates.resetCurrentTemplate()
    next()
  }

  /**
   * * Получить список шаблонов писем
   * @returns список шаблонов писем
   */
  async getEmailTemplates (pageParams?: PageParams, save: boolean = true) {
    this.$wait.start('getEmailTemplates')
    try {
      return await this.$emailTemplates.getEmailTemplates(pageParams, save)
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getEmailTemplates')
    }
  }

  /**
   * * Создать шаблон письма
   * @returns шаблон письма
   */
  async createEmailTemplate () {
    this.$wait.start('createEmailTemplate')
    try {
      const data = await this.$emailTemplates.createEmailTemplate()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Шаблон ${data.name} создан`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('createEmailTemplate')
    }
  }

  /**
   * * Изменить шаблон письма
   * @returns шаблон письма
   */
  async editEmailTemplate () {
    this.$wait.start('editEmailTemplate')
    try {
      const data = await this.$emailTemplates.editEmailTemplate()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Шаблон ${data.name} изменен`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('editEmailTemplate')
    }
  }

  /**
   * * Удалить шаблон письма
   * @returns шаблон письма
   */
  async removeEmailTemplate (id: number) {
    this.$wait.start('deleteEmailTemplate')
    try {
      const data = await this.$emailTemplates.removeEmailTemplate(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Шаблон ${data.name} удален`
      })
      return data
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('deleteEmailTemplate')
    }
  }

  /**
   * * Отправить письма
   * @param id - id шаблона письма
   */
  async sendEmail (id: number) {
    this.$wait.start('sendEmail')
    try {
      await this.$emailTemplates.sendEmail(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: 'Письма отправлены'
      })
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('sendEmail')
    }
  }
}
