import { Component, Vue } from 'nuxt-property-decorator'

@Component
export default class ReindexerMixin extends Vue {
  /**
   * * Export reindexer
   */
  async reindexerExport () {
    this.$wait.start('reindexerExport')
    this.$notify({
      title: 'Началась выгрузка в reindexer',
      message: 'Это может занять некоторое время'
    })
    try {
      const status = await this.$reindexer.export()
      if (status) {
        this.$notify({
          title: 'Выполнено',
          message: 'Выгрузка в reindexer прошла успешно',
          type: 'success'
        })
      } else {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          message: 'Ошибка при выгрузке в reindexer'
        })
      }
    } catch (e:any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('reindexerExport')
    }
  }

  /**
   * * Выгрузка без товаров
   */
  async partialExport () {
    this.$wait.start('partialExport')
    this.$notify({
      title: 'Началась выгрузка в reindexer без товаров',
      message: 'Это может занять некоторое время'
    })
    try {
      const status = await this.$reindexer.partialExport()
      if (status) {
        this.$notify({
          title: 'Выполнено',
          message: 'Выгрузка в reindexer без товаров прошла успешно',
          type: 'success'
        })
      } else {
        this.$notify({
          type: 'error',
          title: 'Ошибка',
          message: 'Ошибка при выгрузке в reindexer без товаров '
        })
      }
    } catch (e:any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('partialExport')
    }
  }
}
