import { Vue, Component } from 'nuxt-property-decorator'
import { PageParams } from '~/store/interfaces'
import pageSizes from '~/utils/page-sizes'

@Component
export default class FormsMixin extends Vue {
  beforeRouteLeave (_to: any, _from: any, next: () => void) {
    this.$wait.start('leaveRouter')
    this.$forms.resetForm()
    this.$forms.resetFormType()
    next()
  }

  // ? ___________Form Types___________
  /**
   * * Сделать запрос на получение списока типов форм
   * @returns список типов форм
   */
  async getFormTypes () {
    try {
      this.$wait.start('getFormTypes')
      return await this.$forms.getFormTypes()
    } catch (e:any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('getFormTypes') }
  }

  /**
   * * Сделать запрос на создание формы
   * @returns тип формы
   */
  async createFormType () {
    try {
      this.$wait.start('changeFormType')
      const data = await this.$forms.createFormType()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Создан тип "${data.name}"`
      })
      return data
    } catch (e:any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('changeFormType') }
  }

  /**
   * * Сделать запрос на удаление типа формы
   * @returns тип формы
   */
  async removeFormType (id: number) {
    try {
      this.$wait.start('removeType')
      const data = await this.$forms.removeFormType(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Тип формы "${data.name}" удален`
      })
      return data
    } catch (e:any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removeType') }
  }

  /**
   * * Сделать запрос на изменение типа формы
   * @returns тип формы
   */
  async editFormType () {
    try {
      this.$wait.start('changeFormType')
      const data = await this.$forms.editFormType()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Изменен тип "${data.name}"`
      })
      return data
    } catch (e:any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('changeFormType') }
  }

  // ? ___________Form List___________
  /**
   * * Запрос на все получение списка форм
   * @param pageParams - параметры пагенатора (необязательные)
   * @returns список форм
   */
  async getForms (pageParams?: PageParams) {
    this.$wait.start('getForms')
    try {
      return await this.$forms.getForms({
        page: +(pageParams?.page ?? 1),
        pageSize: +(pageParams?.pageSize ?? pageSizes.default),
        order: pageParams?.order || undefined,
        sort: pageParams?.sort || undefined
      })
    } catch (e:any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getForms')
    }
  }

  /**
  * * Запрос на изменение form
   * @returns форма
  */
  async editForm () {
    try {
      this.$wait.start('changeForm')
      const form = await this.$forms.editForm()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Изменена ${form.id} форма`
      })
      return form
    } catch (e:any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('changeForm') }
  }

  /**
  * * Запрос на создание form
   * @returns форма
  */
  async createForm () {
    try {
      this.$wait.start('changeForm')
      const form = await this.$forms.createForm()
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Добавлена ${form.id} форма`
      })
      return form
    } catch (e:any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('changeForm') }
  }

  /**
  * * Запрос на удаление form
  * @param id - id формы
   * @returns форма
  */
  async removeForm (id : number) {
    try {
      this.$wait.start('removeForm')
      const data = await this.$forms.removeForm(id)
      this.$notify({
        type: 'success',
        title: 'Выполнено',
        message: `Форма ${data.id} удалена`
      })
    } catch (e:any) {
      console.error({ statusCode: e.error_code, message: e.error_message })
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally { this.$wait.end('removeForm') }
  }

  async getStats () {
    try {
      this.$wait.start('getStats')
      return await this.$forms.getStats()
    } catch (e: any) {
      console.error(e)
      this.$notify({
        type: 'error',
        title: e.error_code,
        message: e.error_message
      })
      throw e
    } finally {
      this.$wait.end('getStats')
    }
  }
}
