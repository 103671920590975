import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators'
import axios, { AxiosError } from 'axios'
import { FormError } from '../../interfaces'
import { OrderRequest, OrderGetTicket, Order } from './interfaces'
import { $axios } from '~/utils/api'

@Module({
  name: 'orderTicket',
  stateFactory: true,
  namespaced: true
})

export default class OrderTicketModule extends VuexModule {
  /**
   * * Информация о тикете
   */
  ticketValue: OrderGetTicket = {
    id: undefined,
    orderID: undefined,
    trackingNumber: '',
    trackingStatus: '',
    transportCompany: '',
    registrationRequestErrors: undefined,
    params: {},
    createdAt: undefined,
    updatedAt: undefined,
    authorID: undefined,
    status: '',
    registrationRequest: {
      additional_order_types: [],
      comment: '',
      date_invoice: '',
      delivery_point: '',
      delivery_recipient_cost: {
        value: undefined,
        vat_rate: undefined,
        vat_sum: undefined
      },
      delivery_recipient_cost_adv: [
        {
          value: undefined,
          threshold: undefined,
          vat_rate: undefined,
          vat_sum: undefined
        }
      ],
      developer_key: '',
      from_location: {
        address: '',
        city: '',
        code: undefined,
        country_code: '',
        fias_guid: '',
        kladr_code: '',
        latitude: undefined,
        longitude: undefined,
        postal_code: '',
        region: '',
        region_code: undefined,
        sub_region: ''
      },
      is_client_return: undefined,
      number: '',
      packages: [],
      print: '',
      recipient: {
        contragent_type: '',
        company: '',
        email: '',
        name: '',
        passport_date_of_birth: '',
        passport_date_of_issue: '',
        passport_number: '',
        passport_organization: '',
        passport_series: '',
        phones: [],
        tin: ''
      },
      seller: {
        address: '',
        inn: '',
        name: '',
        ownership_form: undefined,
        phone: ''
      },
      sender: {
        company: '',
        email: '',
        name: '',
        passport_date_of_birth: '',
        passport_date_of_issue: '',
        passport_number: '',
        passport_organization: '',
        passport_series: '',
        phones: [
          {
            number: '',
            additional: ''
          }
        ],
        tin: ''
      },
      services: [
        {
          code: '',
          parameter: ''
        }
      ],
      shipment_point: '',
      shipper_address: '',
      shipper_name: '',
      tariff_code: undefined,
      to_location: {
        address: undefined,
        city: undefined,
        code: undefined,
        country_code: undefined,
        fias_guid: undefined,
        kladr_code: undefined,
        latitude: undefined,
        longitude: undefined,
        postal_code: undefined,
        region: undefined,
        region_code: undefined,
        sub_region: undefined
      },
      type: undefined
    }
  }

  ticketSchemeValue: OrderRequest = {
    type: '',
    description: '',
    data: {
      additional_order_types: {
        type: '',
        description: '',
        items: {
          type: '',
          description: ''
        }
      },
      comment: undefined,
      date_invoice: undefined,
      delivery_point: undefined,
      delivery_recipient_cost: undefined,
      delivery_recipient_cost_adv: undefined,
      developer_key: undefined,
      from_location: undefined,
      is_client_return: undefined,
      number: undefined,
      packages: {
        type: '',
        description: '',
        items: {
          type: '',
          description: '',
          data: {
            comment: {
              type: '',
              description: ''
            },
            height: {
              type: '',
              description: ''
            },
            items: {
              type: '',
              description: '',
              items: {
                type: '',
                description: '',
                data: {
                  amount: {
                    type: '',
                    description: ''
                  },
                  brand: {
                    type: '',
                    description: ''
                  },
                  cost: {
                    type: '',
                    description: ''
                  },
                  country_code: {
                    type: '',
                    description: ''
                  },
                  marking: {
                    type: '',
                    description: ''
                  },
                  material: {
                    type: '',
                    description: ''
                  },
                  name: {
                    type: '',
                    description: ''
                  },
                  name_i18n: {
                    type: '',
                    description: ''
                  },
                  payment: {
                    type: '',
                    description: '',
                    data: {
                      value: {
                        type: '',
                        description: ''
                      },
                      vat_rate: {
                        type: '',
                        description: ''
                      },
                      vat_sum: {
                        type: '',
                        description: ''
                      }
                    }
                  },
                  url: {
                    type: '',
                    description: ''
                  },
                  ware_key: {
                    type: '',
                    description: ''
                  },
                  weight: {
                    type: '',
                    description: ''
                  },
                  weight_gross: {
                    type: '',
                    description: ''
                  },
                  wifi_gsm: {
                    type: '',
                    description: ''
                  }
                }
              }
            },
            length: {
              type: '',
              description: ''
            },
            number: {
              type: '',
              description: ''
            },
            weight: {
              type: '',
              description: ''
            },
            width: {
              type: '',
              description: ''
            }
          },
          defaultValue: []
        }
      },
      print: undefined,
      recipient: {
        type: '',
        description: '',
        data: {
          company: undefined,
          email: undefined,
          name: {
            type: '',
            description: ''
          },
          passport_date_of_birth: undefined,
          passport_date_of_issue: undefined,
          passport_number: undefined,
          passport_organization: undefined,
          passport_series: undefined,
          phones: {
            type: '',
            description: '',
            items: {
              type: '',
              description: '',
              data: {
                additional: undefined,
                number: {
                  type: '',
                  description: ''
                }
              }
            }
          },
          tin: undefined
        }
      },
      seller: undefined,
      sender: {
        type: '',
        description: '',
        data: {
          company: undefined,
          email: undefined,
          name: {
            type: '',
            description: ''
          },
          passport_date_of_birth: undefined,
          passport_date_of_issue: undefined,
          passport_number: undefined,
          passport_organization: undefined,
          passport_series: undefined,
          phones: {
            type: '',
            description: '',
            items: {
              type: '',
              description: '',
              data: {
                additional: undefined,
                number: {
                  type: '',
                  description: ''
                }
              }
            }
          },
          tin: undefined
        }
      },
      services: undefined,
      shipment_point: undefined,
      shipper_address: undefined,
      shipper_name: undefined,
      tariff_code: {
        type: '',
        description: ''
      },
      to_location: undefined,
      type: {
        type: '',
        description: ''
      }
    }
  }

  errorsTicketValue: any = {

  }

  // ? ______________ getters ______________
  /**
   * * Получить значение тикета
   */
  get ticket (): OrderGetTicket {
    return this.ticketValue
  }

  /**
   * *  Получить значение шаблон тикета
   */
  get ticketScheme (): OrderRequest {
    return this.ticketSchemeValue
  }

  get errorsTicket (): any {
    return this.errorsTicketValue
  }

  // ? ______________ setters ______________
  /**
   * * Установить значение тикета
   * @param filters - значение тикета
   */
  @Mutation
  setTicketValue (val: OrderGetTicket) {
    this.ticketValue = val
  }

  /**
   * * Установить значение шаблон тикета
   * @param filters - значение шаблон тикета
   */
  @Mutation
  setTicketSchemeValue (val: OrderRequest) {
    this.ticketSchemeValue = val
  }

  @Mutation
  setErrorsTicket (val: any) {
    this.errorsTicketValue = val
  }

  /**
   * * Установить значение шаблон тикета
   * @param filters - значение шаблон тикета
   */
  @Mutation
  resetTicketValue () {
    this.ticketValue = {
      id: undefined,
      orderID: undefined,
      trackingNumber: '',
      trackingStatus: '',
      transportCompany: '',
      registrationRequestErrors: undefined,
      params: {},
      createdAt: undefined,
      updatedAt: undefined,
      authorID: undefined,
      status: '',
      registrationRequest: {
        additional_order_types: [],
        comment: '',
        date_invoice: '',
        delivery_point: '',
        delivery_recipient_cost: {
          value: undefined,
          vat_rate: undefined,
          vat_sum: undefined
        },
        delivery_recipient_cost_adv: [
          {
            value: undefined,
            threshold: undefined,
            vat_rate: undefined,
            vat_sum: undefined
          }
        ],
        developer_key: '',
        from_location: {
          address: '',
          city: '',
          code: undefined,
          country_code: '',
          fias_guid: '',
          kladr_code: '',
          latitude: undefined,
          longitude: undefined,
          postal_code: '',
          region: '',
          region_code: undefined,
          sub_region: ''
        },
        is_client_return: undefined,
        number: '',
        packages: [],
        print: '',
        recipient: {
          contragent_type: '',
          company: '',
          email: '',
          name: '',
          passport_date_of_birth: '',
          passport_date_of_issue: '',
          passport_number: '',
          passport_organization: '',
          passport_series: '',
          phones: [],
          tin: ''
        },
        seller: {
          address: '',
          inn: '',
          name: '',
          ownership_form: undefined,
          phone: ''
        },
        sender: {
          company: '',
          email: '',
          name: '',
          passport_date_of_birth: '',
          passport_date_of_issue: '',
          passport_number: '',
          passport_organization: '',
          passport_series: '',
          phones: [
            {
              number: '',
              additional: ''
            }
          ],
          tin: ''
        },
        services: [
          {
            code: '',
            parameter: ''
          }
        ],
        shipment_point: '',
        shipper_address: '',
        shipper_name: '',
        tariff_code: undefined,
        to_location: {
          address: undefined,
          city: undefined,
          code: undefined,
          country_code: undefined,
          fias_guid: undefined,
          kladr_code: undefined,
          latitude: undefined,
          longitude: undefined,
          postal_code: undefined,
          region: undefined,
          region_code: undefined,
          sub_region: undefined
        },
        type: undefined
      }
    }
  }

  // ? ______________________________________actions______________________________________
  /**
   * *
   * @param id заказа
   */
  @Action({
    rawError: true,
    commit: 'setTicketValue'
  })
  async getTicketValue ({
    siteApiUrl,
    id
  }: {
    siteApiUrl: string;
    id: number | undefined;
  }) {
    try {
      this.resetTicketValue()
      const { data } = await $axios.get(`${siteApiUrl}/delivery/v1/cdek/order?orderId=${id}`)
      const response: OrderGetTicket = data
      return response
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

    /**
   * * Оформление заказа в СДЭК
   */
    @Action({
      rawError: true
    })
  async createCdekTicket ({
    siteApiUrl,
    id
  }: {
    siteApiUrl: string;
    id: number | undefined;
  }) {
    try {
      this.setErrorsTicket({})
      const data = await $axios.post(`${siteApiUrl}/delivery/v1/cdek/order?draftId=${id}`)
      const response: any = data
      this.setErrorsTicket({})
      return response
    } catch (error) {
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<FormError>
        if (axiosError.response && axiosError.response.status === 400) {
          this.setErrorsTicket(axiosError.response.data)
          throw axiosError.response.data
        }
      }
      throw new FormError(error as AxiosError<FormError>)
    }
  }

      /**
   * * Сохранение черновика тикета
   */
      @Action({
        rawError: true
      })
    async createDraftTicket ({
      siteApiUrl,
      ticketValue
    }: {
      siteApiUrl: string;
      ticketValue: Order;
    }) {
      try {
        const { data: { data } } = await $axios.post(`${siteApiUrl}/delivery/v1/cdek/order/draft`, ticketValue)
        const response: any = data
        return response
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }

      /**
   * *
   * @param id заказа
   */
  @Action({
    rawError: true,
    commit: 'setTicketSchemeValue'
  })
      async getTicketSchemeValue ({
        siteApiUrl,
        id
      }: {
        siteApiUrl: string;
        id: number | undefined;
      }) {
        try {
          const { data: { data } } = await $axios.get(`${siteApiUrl}/delivery/v1/cdek/order/request?shopOrderId=${id}`)
          const response: OrderRequest = data

          return response
        } catch (error) {
          throw new FormError(error as AxiosError<FormError>)
        }
      }

  /**
   * * Запрос на excel файл со списоком ежедневной статистики по заказам с учетом статуса
   * @param params - параметры запроса
   * @returns excel файл списком ежедневной статистики
   */
  @Action({
    rawError: true
  })
  async getStatusesByDayExcel ({
    siteApiUrl,
    id
  }: {
      siteApiUrl: string;
      id: number | undefined;
    }): Promise<Blob> {
    try {
      const { data } = await $axios.get(`${siteApiUrl}/delivery/v1/cdek/order/pdf?orderId=${id}`, {
        responseType: 'blob'
      })
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

    /**
   * * Запрос на excel файл со списоком ежедневной статистики по заказам с учетом статуса
   * @param params - параметры запроса
   * @returns excel файл списком ежедневной статистики
   */
    @Action({
      rawError: true
    })
  async getTicketBarcode ({
    siteApiUrl,
    id
  }: {
        siteApiUrl: string;
        id: number | undefined;
      }): Promise<Blob> {
    try {
      const { data } = await $axios.get(`${siteApiUrl}/delivery/v1/cdek/order/barcode-receipts?id=${id}`, {
        responseType: 'blob'
      })
      return data
    } catch (error) {
      throw new FormError(error as AxiosError<FormError>)
    }
  }

    /**
   * * Запрос на excel файл со списоком ежедневной статистики по заказам с учетом статуса
   * @param params - параметры запроса
   * @returns excel файл списком ежедневной статистики
   */
  @Action({
    rawError: true
  })
    async getTicketInvoice ({
      siteApiUrl,
      id
    }: {
      siteApiUrl: string;
      id: number | undefined;
    }): Promise<Blob> {
      try {
        const { data } = await $axios.get(`${siteApiUrl}/delivery/v1/cdek/order/receipts?id=${id}`, {
          responseType: 'blob'
        })
        return data
      } catch (error) {
        throw new FormError(error as AxiosError<FormError>)
      }
    }
}
