import { Context } from '@nuxt/types'
import { Bill, BillsInput, BillsPlugin } from './interfaces'
import { ResponseData } from '~/store/interfaces'

export default function getPluginApi (store:any) {
  const ctx: Context = this

  const api: BillsPlugin = {
    // ? ________ getters ________
    get billsList (): ResponseData<Bill> {
      return store.billsList
    },
    get currentBill (): Bill {
      return store.currentBill
    },

    get currentBillQr (): any {
      return store.currentBillQr
    },

    get filters (): BillsInput {
      return store.filters
    },
    // ? ________ setters ________

    set billsList (bills: ResponseData<Bill>) {
      store.setBillsList(bills)
    },
    set currentBill (bill: Bill) {
      store.setCurrenBill(bill)
    },

    set currentBillQr (billQr: any) {
      store.setCurrenBillQr(billQr)
    },

    // ? ________ actions ________

    getBills (pageParams: BillsInput | null = null): Promise<ResponseData<Bill>> {
      return store.getBills(pageParams)
    },

    getBill (id: number): Promise<Bill> {
      return store.getBill({ siteApiUrl: ctx.$config.SITE_API, id })
    },

    getBillQr (id: number): Promise<any> {
      return store.getBillQr({ siteApiUrl: ctx.$config.SITE_API, id })
    }
  }
  return api
}
