import { ReindexerPlugin } from '~/store/modules/reindexer/interfaces'

export default function getPluginApi (reindexerStore:any) {
  const api: ReindexerPlugin = {
    export (): Promise<boolean> {
      return reindexerStore.export()
    },

    partialExport (): Promise<boolean> {
      return reindexerStore.partialExport()
    }
  }
  return api
}
