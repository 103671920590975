import { CurrenciesPlugin, Currency, GeneratorOutput } from './interfaces'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getCurrenciesApi (currenciesStore:any) {
  const api: CurrenciesPlugin = {
    // ? ________ getters ________

    get currencies (): ResponseData<Currency> {
      return currenciesStore.currencies
    },
    get validators (): ValidatorParams {
      return currenciesStore.validators
    },

    get currency (): Currency {
      return currenciesStore.currency
    },

    currencyById (id: number): Currency | undefined {
      return currenciesStore.currencyById(id)
    },

    // ? ________ setters ________

    set currencies (currencies: ResponseData<Currency>) {
      currenciesStore.setCurrencies(currencies)
    },

    set currency (currency: Currency) {
      currenciesStore.setCurrency(currency)
    },

    resetCurrency () {
      currenciesStore.resetCurrency()
    },

    resetCurrencies () {
      currenciesStore.resetCurrencies()
    },

    // ? ________ actions ________

    async getCurrencies (pageParams: PageParams | null = null): Promise<ResponseData<Currency>> {
      return await currenciesStore.getCurrencies(pageParams)
    },

    updateRate (): Promise<GeneratorOutput> {
      return currenciesStore.updateRate()
    },

    createCurrency () {
      return currenciesStore.createCurrency()
    },

    editCurrency () {
      return currenciesStore.editCurrency()
    },

    getCurrency (id: number) {
      return currenciesStore.getCurrency(id)
    },

    removeCurrency (id: number) {
      return currenciesStore.removeCurrency(id)
    }
  }

  return api
}
