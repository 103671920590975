import { FormsPlugin, Filters, Form, FormType } from './interfaces'
import { PageParams, ResponseData, ValidatorParams } from '~/store/interfaces'

export default function getPluginApi (formsStore:any) {
  const api: FormsPlugin = {
    // ? ________ getters ________
    get filters (): Filters {
      return formsStore.filters
    },

    get forms (): ResponseData<Form> {
      return formsStore.forms
    },

    get form (): Form {
      return formsStore.form
    },
    // * Валидна ли форма
    get validateDataForm (): boolean {
      return formsStore.validateDataForm
    },

    get formsValidators (): ValidatorParams {
      return formsStore.formsValidators
    },

    get typeFormsValidators (): ValidatorParams {
      return formsStore.typeFormsValidators
    },

    formById (id: number): Form | undefined {
      return formsStore.formById(id)
    },

    get formTypes (): ResponseData<FormType> {
      return formsStore.formTypes
    },

    get formType (): FormType {
      return formsStore.formType
    },

    formTypeById (id: number): FormType | undefined {
      return formsStore.formTypeById(id)
    },

    // ? ________ setters ________

    set filters (filters: Filters) {
      formsStore.setFilters(filters)
    },

    resetFilters () {
      formsStore.resetFilters()
    },

    set forms (forms: ResponseData<Form>) {
      formsStore.setForms(forms)
    },

    set form (form: Form) {
      formsStore.setForm(form)
    },

    resetForm () {
      formsStore.resetForm()
    },

    resetForms () {
      formsStore.resetForms()
    },

    set formTypes (formTypes: ResponseData<FormType>) {
      formsStore.setFormTypes(formTypes)
    },

    set formType (formType: FormType) {
      formsStore.setFormType(formType)
    },

    resetFormType () {
      formsStore.resetFormType()
    },

    resetFormTypes () {
      formsStore.resetFormTypes()
    },

    // ? ________ actions ________

    async getForms (pageParams?: PageParams | null): Promise<ResponseData<Form>> {
      return await formsStore.getForms(pageParams)
    },

    getFormById (id: number): Promise<Form> {
      return formsStore.getFormById(id)
    },

    createForm (): Promise<Form> {
      return formsStore.createForm()
    },

    editForm (): Promise<Form> {
      return formsStore.editForm()
    },

    removeForm (id): Promise<Form> {
      return formsStore.removeForm(id)
    },

    async getFormTypes (pageParams?: PageParams | null): Promise<ResponseData<FormType>> {
      return await formsStore.getFormTypes(pageParams)
    },

    getFormTypeById (id): Promise<FormType> {
      return formsStore.getFormTypeById(id)
    },

    createFormType (): Promise<FormType> {
      return formsStore.createFormType()
    },

    editFormType (): Promise<FormType> {
      return formsStore.editFormType()
    },

    removeFormType (id: number): Promise<FormType> {
      return formsStore.removeFormType(id)
    },

    getStats (): Promise<Blob> {
      return formsStore.getStats()
    }
  }

  return api
}
